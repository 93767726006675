import ReactDOM from "react-dom";
import React from "react";
import "./styles.scss";

const App = () => {
  return null;
};

const root = document.getElementById("root");
ReactDOM.render(<App />, root);
